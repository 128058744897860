import React from 'react'
import { useNavigate } from 'react-router-dom'
import '../stylesheets/TopComponent.css'

const TopComponent = () => {
	const history = useNavigate()

	function navigation(ziel) {
		history(`${ziel}`)
	}

	return (
		<div className='TopComponent'>
			<button onClick={() => navigation('/')} className='button'>
				Startseite
			</button>
			<button onClick={() => navigation('/gallery')} className='button'>
				Galerie
			</button>
			<button onClick={() => navigation('/code')} className='button'>
				Code
			</button>
		</div>
	)
}

export default TopComponent
