import React from 'react'
import '../stylesheets/Main.css'

const Main = () => {
	return (
		<div className='Main'>
			<h1>
				Gute Arbeit! Ich hätte nicht gedacht, dass ihr diese Seite findet.
			</h1>
			<h2>Nicht mehr lange, dann gehört Horst für immer mir!</h2>
		</div>
	)
}

export default Main
