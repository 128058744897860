import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import TopComponent from './routes/TopComponent'
import Gallery from './routes/Gallery'
import Main from './routes/Main'
import Code from './routes/Code'

function App() {
	return (
		<div className='App'>
			<BrowserRouter>
				<TopComponent />
				<Routes>
					<Route path='/' element={<Main />} />
					<Route path='/code' element={<Code />} />
					<Route path='/gallery' element={<Gallery />} />
				</Routes>
			</BrowserRouter>
		</div>
	)
}

export default App
