import React from 'react'
import Hellbraunes_Pferd from '../images/Hellbraunes_Pferd.jpg'
import Schwarzes_Pferd_nah from '../images/Schwarzes_Pferd_nah.jpg'
import Schwarzes_Pferd_weit from '../images/Schwarzes_Pferd_weit.jpg'
import Weisses_Pferd_nah from '../images/Weißes_Pferd_nah.jpg'
import Weisses_Pferd_weit from '../images/Weißes_Pferd_weit.jpg'

const Gallery = () => {
	return (
		<div>
			<img src={Hellbraunes_Pferd} alt='Hellbraunes Pferd' />
			<img src={Schwarzes_Pferd_nah} alt='Schwarzes Pferd aus der Nähe' />
			<img src={Schwarzes_Pferd_weit} alt='Schwarzes Pferd aus der Ferne' />
			<img src={Weisses_Pferd_nah} alt='Weißes Pferd aus der Nähe' />
			<img src={Weisses_Pferd_weit} alt='Weißes Pferd aus der Ferne' />
		</div>
	)
}

export default Gallery
